.skills__container.container {
  max-width: 1000px;
  margin: 0 auto;
}

.skills__container {
  p {
    margin: 0;
  }
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    list-style: none;
    @media (max-width: 768px) {
      padding-left: 20px;
    }
    li::before {
      content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
      color: var(--green); /* Change the color */
      display: inline-block; /* Needed to add space between the bullet and the text */
      width: 1em; /* Also needed for space (tweak if needed) */
      margin-left: -1em; /* Also needed for space (tweak if needed) */
    }
  }
}
