#about {
  .container {
    max-width: 1000px;
  }
}
.about__container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}
.about__left {
  max-width: 600px;
  p {
    margin: 0;
  }
}

.about__right {
  display: flex;
  justify-content: center;
}
.about__right-image {
  width: 300px;
  height: 300px;
  border-radius: 4px;
  overflow: hidden;
  @media (max-width: 768px) {
    width: 250px;
    height: 250px;
    margin: 50px 0;
  }
  img {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
}

#skills {
  margin-top: 30px;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
}
