a {
  color: inherit;
  text-decoration: none;
}

.app {
  height: 100%;
  width: 100%;
  background-color: var(--dark-navy);
  color: var(--slate);
  position: relative;
}

.outlined-btn {
  color: var(--lightest-slate);
  padding: 8px 20px;
  background-color: transparent;
  border: 1px var(--green) solid;
  border-radius: 4px;
  color: var(--green);
  cursor: pointer;
  font-size: 14px;
  font-family: "Roboto Mono", monospace;
  &:hover {
    background-color: var(--green-tint);
    transition: all ease-in 0.35;
  }
}

.subTitle {
  font-family: "Roboto Mono", monospace;
  color: var(--green);
  font-size: 16px;
}

.big-heading {
  font-size: clamp(40px, 8vw, 80px);
  margin: 0;
}

.container {
  max-width: 1250px;
  margin: 0 auto;
  padding: 6rem 7rem;
  @media (max-width: 768px) {
    padding: 30px 20px;
  }
}

.bar-padding {
  padding-top: 30px;
  @media (max-width: 768px) {
    padding-top: 35px;
  }
}
#logo {
  @media (max-width: 768px) {
    display: none;
  }
}

.scroll-container {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 45%;
  bottom: 10%;
  @media (max-width: 768px) {
    bottom: 30vh;
  }
}
.scroll-box {
  display: block;
  width: 30px;
  height: 60px;
  border: 3px solid var(--slate);
  border-radius: 15px;
  position: relative;

  @media (max-width: 320px) {
    display: none;
  }
}

.scroller {
  display: block;
  width: 16px;
  border-radius: 8px;
  background-color: var(--slate);
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 34px;
  animation: scroller 1500ms ease-out infinite;
}

@keyframes scroller {
  0% {
    bottom: 34px;
  }
  5% {
    top: 4px;
  }
  32% {
    bottom: 4px;
  }
  66% {
    top: 34px;
    bottom: 4px;
  }
  100% {
    top: 4px;
    bottom: 34px;
  }
}
