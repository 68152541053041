#header {
  .header__container {
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 7rem;
    position: relative;
    height: calc(100vh - 70px);
    display: flex;
    justify-content: space-between;

    @media (max-width: 768px) {
      justify-content: flex-start;
      padding: 0 20px;
      height: calc(100vh - 80px);
      top: 80px;
      left: 0;
      flex-direction: column;
    }
    .header__content {
      .header__title h5 {
        margin: 0;

        &:nth-child(1) {
          color: var(--lightest-slate);
        }
      }
      .header__text {
        max-width: 500px;
      }
    }
  }
}
.header__left {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__right {
  display: flex;
  justify-content: center;
  align-items: center;
}
