:root {
  --dark-navy: #020b18;
  --navy: #050b14;
  --light-navy: #031227;
  --lightest-navy: #141e30;
  --navy-shadow: rgba(1, 9, 20, 0.7);
  --dark-slate: #495670;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green: #64f5ff;
  --green-tint: rgba(100, 255, 218, 0.1);
  --blue: #1597bb;
  --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
  --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  --bottom-shadow: 2px 3px 9px 2px rgba(0, 5, 12, 0.35);
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  font-family: "DM Sans", sans-serif;
  font-size: 17px;
  background-color: var(--dark-navy);
  line-height: 1.4;
}
html {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h5,
h2,
h1 {
  margin: 0;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 17px;
  }
}
